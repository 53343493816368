import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20230418-Rate-Sheet.pdf";
const date = "18.04.2023";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>

    <br />
    <p>With effect from Tuesday 18 April 2023 we will be making the following changes to our range:</p>
    <p><strong>Summary of changes</strong></p>
    <p>We are pleased to introduce a selection of new cashback rates for our UK Residential Remortgage customers. These are available on our Two Year Fixed Standard and Fee Saver and Five Year Fixed Standard and Fee Saver offerings alongside our existing UK Residential Remortgage product range, which includes fees assisted legals. This gives our remortgage customers a greater choice of whether they would like to choose from a cashback incentive (choose and pay for their own solicitor) or free standard legal fees incentive (use HSBC Managed Panel solicitor) and these can be found clearly labelled on the Mortgage Rate Sheet.</p>
    <p><strong>UK Residential Existing Customer Switching Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 70%, 75% and 80% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 80% and 90% LTV have reduced</li>
      <li>3 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60% LTV has reduced</li>
      <li>5 Year Fixed Standard at 60% and 90% LTV have reduced</li>
      <li>5 Year Premier Exclusive at 60% and 90% LTV have reduced</li>
    </ul>
    <p><strong>UK Residential Existing Customer Borrowing More Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 70%, 75% and 80% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 80% and 90% LTV have reduced</li>
      <li>3 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60% LTV has reduced</li>
      <li>5 Year Fixed Standard at 60% and 90% LTV have reduced</li>
      <li>5 Year Premier Exclusive at 60% and 90% LTV have reduced</li>
    </ul>
    <p><strong>UK Residential First Time Buyer Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
    </ul>
    <p><strong>UK Residential Home Mover Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
    </ul>
    <p><strong>UK Residential Remortgage Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75% and 80% LTV have reduced</li>
      <li>2 Year Fixed Standard at 70%, 75%, 80% and 85% LTV have reduced</li>
      <li>3 Year Fixed Standard at 60% LTV has reduced</li>
      <li>5 Year Fixed Fee Saver at 60% and 80% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Premier Exclusive at 60%, 80%, 85% and 90% LTV have reduced</li>
    </ul>
    <p><strong>International Residential Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70% and 75% % LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have reduced</li>
      <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70% and 75% LTV have reduced</li>
      <li>5 Year Premier Exclusive at 60%, 70% and 75% LTV have reduced</li>
    </ul>
    <p>There are no changes to any other interest rates or our fixed rate end dates at this time.</p>
    <p>An updated mortgage rate sheet will be published on Tuesday 18 April to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
